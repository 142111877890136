
<script setup lang="ts">
    import { isMobile } from "o365.GlobalState.ts";
    import { useAsyncComponent } from "o365-vue-utils";

    export interface IProps {
        header: string;
        href?: string;
        dueDate?: Date
    };

    const MCard = useAsyncComponent("o365-mobile/MCard");
    const dateNow = new Date();
    
    const parseDate = (date) =>{
        return new Date(date);
    }

    const props = defineProps<IProps>();
</script>

<template>
    <template v-if="isMobile && false">
        <a class="" style="margin: 0 -0.5rem; padding: 0.25rem 0.5rem;" :title="header" :href="href">
            <div>{{ header }}</div>
        </a>
    </template>
    <template v-else-if="isMobile">
        <MCard :header="header" :href="href" shadow="none" />
    </template>
    <template v-else>
        <div>
            <a class="d-inline-block" :title="header" :href="href">
                <div>{{ header }}
                    <span v-if="props.dueDate" :class="{ dueDatePassed: parseDate(props.dueDate) < dateNow }">
                        ({{$formatDate(props.dueDate, 'Short Date')}})
                    </span>
                </div>
            </a>
        </div>
    </template>
</template>

<style scoped>
    .c-section-item {

    }

    .c-section-item:first-child {
        border-radius: 0.5rem 0.5rem 0 0;
    }

    .c-section-item:last-child {
        border-radius: 0 0 0.5rem 0.5rem;
    }

    .c-section-item:not(:last-child) {
        border-bottom: none !important;
    }
    .dueDatePassed{
        color: red;
    }
</style>
